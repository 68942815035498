import styled from "styled-components"

import { breakpoint } from "src/constants/breakpoints"
import { backgroundColor as defaultBackgroundColor } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

export function ImageBackdrop({
  landscapeImageUrl,
  portraitImageUrl,
  children,
  backgroundColor,
}: {
  landscapeImageUrl: string
  portraitImageUrl?: string
  children: React.ReactNode
  backgroundColor?: string
}) {
  return (
    <Box $backgroundColor={backgroundColor}>
      <picture>
        <source
          media={`(${breakpoint.mediumUp})`}
          srcSet={`${landscapeImageUrl}`}
        />
        <Image src={`${portraitImageUrl ?? landscapeImageUrl}`} alt="" />
      </picture>

      <Gradient />

      <Grid>
        <ChildBox>{children}</ChildBox>
      </Grid>
    </Box>
  )
}

const Box = styled.div<{
  $backgroundColor?: string
}>`
  position: relative;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || defaultBackgroundColor};
`

const Grid = styled.div`
  display: grid;
  justify-content: stretch;
  align-items: end;
  position: relative;
  min-height: 100dvh;
  z-index: 1;

  @media (${breakpoint.mediumUp}) {
    align-items: center;
  }
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  height: 35dvh;

  @media (${breakpoint.mediumUp}) {
    height: 50dvh;
  }
`

const Gradient = styled.div`
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // height: 50dvh;
  // width: 100%;
  // background: linear-gradient(
  //   180deg,
  //   rgba(65, 125, 221, 0) 50%,
  //   rgba(31, 72, 139, 0.5) 100%
  // );
  // mix-blend-mode: multiply;
`

const ChildBox = styled.div`
  display: grid;
  position: relative;
  margin-top: 25dvh;
  min-height: 75dvh;

  & > * {
    width: 100%;
    margin: 0 auto;
  }

  @media (${breakpoint.mediumUp}) {
    min-height: unset;
    margin: ${spacing.XL2} ${spacing.M};
  }
`
